import { useAuth } from '@/components/context/AuthProvider'
import { useProjectsQuery } from '@/graphql'
import { createdAppDataLocalStore } from '@/helpers/globalVariables'
import { websiteRoutes } from '@/router/routes'
import { CreatedApplicationData } from '@/types'
import { Form, Spin } from 'antd'
import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useLocalStorage } from 'react-use'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const { Title, Paragraph } = Typography
const AssignProject = () => {
  const navigate = useNavigate()
  const { dashboard } = websiteRoutes
  const { pathname } = useLocation()
  const [, setEscLink] = useLocalStorage('esc-link')
  const [appData, setAppData] = useLocalStorage(createdAppDataLocalStore)
  const { setModalType, setIsModalOpen } = useGeneralContext()
  const createAppData = appData as CreatedApplicationData

  const { user } = useAuth()
  const userId = user?.id
  const [isDisabled, setIsDisabled] = useState(true)
  const [selectedProjectId, setSelectedProjectId] = useState('')

  const { data, loading } = useProjectsQuery({
    variables: { filter: { owner: { id: { eq: userId } } } },
    fetchPolicy: globalFetchPolicy,
  })
  const projectsData = data?.projects?.data as ProjectEntity[]
  const selectOptions = useMemo(() => {
    return projectsData
      ?.filter(it => !it?.attributes?.applications?.data?.length)
      .map((it: ProjectEntity) => {
        return {
          label: it?.attributes?.title,
          value: it?.id,
        }
      })
  }, [projectsData])

  useEffect(() => {
    setEscLink(pathname)
  }, [])

  const onConfirm = () => {
    setModalType('selected-project-info')
    setAppData({ ...createAppData, projectId: selectedProjectId })
  }
  const onCreateProject = () => {
    navigate(`${dashboard.createProject}/step-01`)
    setIsModalOpen(false)
  }
  const onChange = (value: string) => {
    setSelectedProjectId(value)
    if (value) setIsDisabled(false)
  }
  return (
    <React.Fragment>
      <Title level={3}>{selectOptions?.length ? 'Select Project' : 'Create Project'}</Title>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {selectOptions?.length ?
          'First let’s create a project using a name familiar to you. All applications that are generated will then be filtered under this project for ease of navigation and reference. If you have an existing project set up you can select it below, alternatively please create a new one. No obligation – nothing will be submitted or charged until you accept our quote'
          : 'First let’s create a project using a name familiar to you. All applications that are generated will then be filtered under this project for ease of navigation and reference. \n' +
          'No obligation – nothing will be submitted or charged until you accept our quote'
        }
      </Paragraph>
      {loading ? (
        <Spin />
      ) : (
        <>
          {selectOptions?.length ? (
            <Form layout={'vertical'} className={'form-content'}>
              <FormItem
                notFoundText={'No empty projects...'}
                name={'project'}
                type={'select'}
                label={'Project'}
                placeholder={'Select the project'}
                selectOptions={selectOptions}
                handleSelectChange={onChange}
              />
            </Form>
          ) : null}
        </>
      )}
      <div className='btn-block' style={{ justifyContent: selectOptions?.length ? 'space-between' : 'flex-end' }}>
        <Button text={'Create project'} btnType={'ghost'} onClick={onCreateProject} />
        {selectOptions?.length ? (
          <Button text={'Confirm selection'} btnType={'primary'} onClick={onConfirm} disabled={isDisabled} />
        ) : null}

      </div>
    </React.Fragment>
  )
}
export default AssignProject
