import { App } from '@/components/app'
import AppMobilePlaceholder from '@/components/app-mobile-placeholder/AppMobilePlaceholder'
import { createRoot } from 'react-dom/client'
import './styles/check-box.less'
import './styles/form-elements.less'
import './styles/global.less'
import './styles/modal.less'
import './styles/popovers.less'
import './styles/tables.less'
import './styles/tabs.less'

function isDesktopDevice() {
  // Check if the device is a desktop based on media query
  return window.matchMedia('(min-width: 1024px)').matches
}

const rootElement = document.querySelector('#root')
if (!rootElement) throw new Error('Failed to find the root element')

if (isDesktopDevice()) {
  createRoot(rootElement).render(<App />)
} else {
  createRoot(rootElement).render(<AppMobilePlaceholder />)
}
