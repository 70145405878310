import { useCompanyQuery, useUpdateCompanyMutation, useUsersQuery } from '@/graphql'
import { Form, message, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title, Paragraph, Text } = Typography
const AssignUser = () => {
  const [searchParams] = useSearchParams()
  const companyId = searchParams.get('id')

  const { setIsModalOpen, setTriggerRefetch } = useGeneralContext()
  const [updateCompany] = useUpdateCompanyMutation()

  const [selectOptions, setSelectOptions] = useState<SelectOptionsType[]>([])

  const { data: companyData, loading: companyDataLoading } = useCompanyQuery({ variables: { id: companyId || '' } })
  const { data, loading } = useUsersQuery({
    variables: { filter: { role: { name: { in: ['Service_provider', 'Client', 'Government', 'Professional'] } } } },
  })
  const usersArray = data?.usersPermissionsUsers?.data as UsersPermissionsUserEntity[]
  const company = companyData?.company?.data
  const companyUsers = company?.attributes?.users?.data?.map(it => it?.id)

  const freeUsersArray = usersArray?.filter((it: UsersPermissionsUserEntity) => !it?.attributes?.company?.data)

  useEffect(() => {
    if (!loading && !companyDataLoading) {
      const selectOptionsArray = freeUsersArray?.map((it: UsersPermissionsUserEntity) => {
        return {
          label: (
            <>
              <Text weight={'w600'}>{it?.attributes?.role?.data?.attributes?.name}</Text>{' '}
              <Text>{`| ${it?.attributes?.email} | ${it?.attributes?.userProfile?.data?.attributes?.fullName}`}</Text>
            </>
          ),
          value: it?.id,
        }
      })
      setSelectOptions(selectOptionsArray as unknown as SelectOptionsType[])
    }
  }, [loading, usersArray, companyDataLoading])

  const onFinish = (value: Record<string, string>) => {
    const restCompanyUsers = companyUsers || []
    companyId && updateCompany({
      variables: {
        id: companyId,
        data: {
          users: [value?.users, ...restCompanyUsers],
        },
      },
    })
      .then(() => {
        BlackNotification('Employee added successfully to a current company')
        setIsModalOpen(false)
        setTriggerRefetch(true)
      })
      .catch(e => {
        message.error('Error saving data. Try again', e.message).then()
      })
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <React.Fragment>
      <Title level={3}>{'Assign employee to a Company'}</Title>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {
          'After assigning employee, please do not forget to select their level of approval in case the current company is Service provider or Government type'
        }
      </Paragraph>
      {loading ? (
        <Spin />
      ) : (
        <Form layout={'vertical'} className={'form-content'} onFinish={onFinish}>
          <FormItem
            name={'users'}
            type={'select'}
            label={'Employee'}
            placeholder={'Select employee'}
            selectOptions={selectOptions as SelectOptionsType[]}
          />
          <div className='btn-block' style={{ justifyContent: 'flex-end' }}>
            <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
            <Button text={'Confirm selection'} btnType={'primary'} htmlType={'submit'} />
          </div>
        </Form>
      )}
    </React.Fragment>
  )
}
export default AssignUser
