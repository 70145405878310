import { defaultValues, GeneralContext } from '@/components/context/GeneralContext'
import { Loader } from '@/components/layout/Loader'
import { Modal } from '@/components/modal/Modal'
import ModalContent from '@/components/modal/ModalContent'
import { getAuthToken } from '@/helpers/auth'
import { Layout } from 'antd'
import { FC, Suspense, useState } from 'react'
import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'
import './index.less'

const PlatformLayout: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(defaultValues.isModalOpen)
  const [createAppCurrentStep, setCreateAppCurrentStep] = useState(defaultValues.createAppCurrentStep)
  const [modalType, setModalType] = useState(defaultValues.modalType)
  const [currentApplicationId, setCurrentApplicationId] = useState(defaultValues.currentApplicationId)
  const [currentId, setCurrentId] = useState(defaultValues.currentId)
  const [locationConfirmationFormStep, setLocationConfirmationFormStep] = useState(
    defaultValues.locationConfirmationFormStep,
  )
  const [triggerRefetch, setTriggerRefetch] = useState<boolean | null>(null)
  const [modalSettings, setModalSettings] = useState(defaultValues.modalSettings)
  const [documentInfo, setDocumentInfo] = useState(defaultValues.documentInfo)
  const [sidebarMode, setSidebarMode] = useState(defaultValues.sidebarMode)
  const [signUpMode, setSignUpMode] = useState(defaultValues.signUpMode)
  const [isPaymentCreated, setIsPaymentCreated] = useState(defaultValues.isPaymentCreated)
  const [isReadyForReview, setIsReadyForReview] = useState(defaultValues.isReadyForReview)
  const [userLevelOfApproval, setUserLevelOfApproval] = useState(defaultValues.userLevelOfApproval)
  const [modalWidth, setModalWidth] = useState(defaultValues.modalWidth)
  const [modalTopOffset, setModalTopOffset] = useState(defaultValues.modalTopOffset)
  const [mode, setMode] = useState(defaultValues.mode)
  const [pageViewMode, setPageViewMode] = useState(defaultValues.pageViewMode)
  const [tabActiveKey, setTabActiveKey] = useState(defaultValues.tabActiveKey)

  if (getAuthToken()) {
    return (
      <GeneralContext.Provider
        value={{
          sidebarMode,
          modalType,
          setModalType,
          isModalOpen,
          setIsModalOpen,
          locationConfirmationFormStep,
          setLocationConfirmationFormStep,
          createAppCurrentStep,
          setCreateAppCurrentStep,
          setSidebarMode,
          triggerRefetch,
          setTriggerRefetch,
          modalSettings,
          setModalSettings,
          currentApplicationId,
          setCurrentApplicationId,
          documentInfo,
          setDocumentInfo,
          signUpMode,
          setSignUpMode,
          currentId,
          setCurrentId,
          isPaymentCreated,
          setIsPaymentCreated,
          isReadyForReview,
          setIsReadyForReview,
          userLevelOfApproval,
          setUserLevelOfApproval,
          modalWidth,
          setModalWidth,
          modalTopOffset,
          setModalTopOffset,
          mode,
          setMode,
          pageViewMode,
          setPageViewMode,
          tabActiveKey,
          setTabActiveKey,
        }}
      >
        <Layout>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Layout>
        <Modal open={isModalOpen} width={modalWidth ? modalWidth : 450} topOffset={modalTopOffset}>
          <ModalContent />
        </Modal>
      </GeneralContext.Provider>
    )
  } else {
    return <Navigate to='/' replace />
  }
}
export { PlatformLayout }
