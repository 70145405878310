import { TOOLTIP_MESSAGES } from '@/components/app'
import { getAuthToken } from '@/helpers/auth'
import { websiteRoutes } from '@/router/routes'
import { UploadedFileAWSResponse } from '@/types/upload'
import { Tooltip, Upload, UploadProps } from 'antd'
import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import Button from '../button/Button'
import { SvgIcon } from '../icon'
import './AddGenerateDocumentBtnBlock.less'

type Props = {
  onUpload: (info: any) => void
  fileList?: UploadedFileAWSResponse[]
}
const AddGenerateDocumentBtnBlock: FC<Props> = ({ onUpload }) => {
  const { dashboard } = websiteRoutes
  const [_, setApprovalDetails] = useLocalStorage('approval-details')
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id') as string
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const props: UploadProps = {
    name: 'files',
    action: `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
    listType: 'picture',
    headers: { Authorization: `Bearer ${getAuthToken()}` },
    multiple: true,
    showUploadList: false,
  }
  const onGenerateClick = () => {
    setApprovalDetails({
      pathname,
      uuid,
    })
    navigate(dashboard.templateSubjects)
  }
  return (
    <div className='add-document-btn-block'>
      <Upload {...props} onChange={info => onUpload(info)}>
        <Button
          size={'small'}
          text={'Add document'}
          icon={<SvgIcon type={'plus'} />}
          btnType={'ghost'}
          mirrored={true}
          style={{ width: '100%' }}
        />
      </Upload>
      <Tooltip title={TOOLTIP_MESSAGES.temporaryUnavailable}>
        <div>
          <Button
            text={'Generate document'}
            icon={<SvgIcon type={'doc-sheet'} />}
            btnType={'ghost'}
            mirrored={true}
            size={'small'}
            onClick={onGenerateClick}
            style={{ width: '100%' }}
            disabled
          />
        </div>
      </Tooltip>
    </div>
  )
}

export default AddGenerateDocumentBtnBlock
