import { useAuth } from '@/components/context/AuthProvider'
import { Form, message, Upload, UploadFile } from 'antd'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useApplicationsQuery,
  useCreateNotificationMessageMutation,
  useDeleteFileMutation,
  useUpdateInvoiceMutation,
} from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import { SvgIcon } from '../../icon'
import Typography from '../../typography/Typography'
import { getAuthToken } from '@/helpers/auth'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { UploadListType } from 'antd/lib/upload/interface'

const { Dragger } = Upload
const { Title, Text, Paragraph } = Typography
const UploadReceipt = () => {
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id')
  const { currentId, setIsModalOpen } = useGeneralContext()
  const [deleteFileMutation] = useDeleteFileMutation()
  const [updateInvoice] = useUpdateInvoiceMutation()
  const [createNotification] = useCreateNotificationMessageMutation()

  const { data } = useApplicationsQuery({ variables: { filter: { uuid: { eq: uuid } } } })
  const applicationData = data?.applications?.data?.[0]

  const deleteFile = (file: UploadFile) => {
    const imgId = file.response?.[0]?.id || file.uid
    if (imgId) {
      deleteFileMutation({ variables: { id: imgId } })
        .then(() => {
          message.success(`File ${file.name} has been deleted successfully.`).then()
        })
        .catch(() => {
          message.error(`File ${file.name} deleting failed.`).then()
        })
    }
  }

  const props = {
    name: 'files',
    action: `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
    listType: 'picture' as UploadListType,
    headers: { Authorization: `Bearer ${getAuthToken()}` },
    multiple: true,
    maxCount: 3,
    onPreview: () => console.log(''),
    onRemove: (file: any) => deleteFile(file),
    showUploadList: {
      removeIcon: <SvgIcon type={'bin'} />,
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`).then()
      }
    },
  }
  type ValuesTypes = {
    documents?: { file: UploadFile; fileList: UploadFile[] }
  }
  const onResitUploadFinish = async (values: ValuesTypes) => {
    const paymentDocuments = values?.documents?.fileList?.map((it: UploadFile) => {
      if (it?.response) return it?.response?.[0]?.id
      if (!it?.response) return it?.uid
    })
    if (currentId) {
      await updateInvoice({
        variables: {
          id: currentId,
          data: {
            paymentDocuments,
            status: 'approval_pending',
          },
        },
        fetchPolicy: globalFetchPolicy,
        refetchQueries: 'active',
        onCompleted: () => {
          BlackNotification('Documents uploaded. Your agent will check and approve your invoice as soon as possible.')
        },
        onError: (error) => {
          message.error(error.message)
        },
      })
      await createNotification({
        variables: {
          input: {
            application: applicationData?.id,
            from: user?.id,
            to: applicationData?.attributes?.agent?.data?.id,
            message: `Proof of payment for application ${applicationData?.attributes?.title} has been successfully uploaded. Your document will be reviewed and confirmed by one of our agents before proceeding with submission steps.`,
          },
        },
        fetchPolicy: globalFetchPolicy,
        refetchQueries: 'active',
      })
      setIsModalOpen(false)
    }
  }
  return (
    <>
      <Form onFinish={onResitUploadFinish}>
        <Title level={3}>{'Upload receipt'}</Title>
        <Form.Item name={'documents'}>
          <Dragger {...props} className={'upload-receipt-dragger'}>
            <>
              <div className='text-block'>
                <Text size={'md'} weight={'w600'}>
                  {'Drop files here'}
                </Text>
                <Paragraph>{'Or, if you prefer...'}</Paragraph>
              </div>
              <Button text={'Select a file'} btnType={'ghost'} style={{ maxWidth: 100 }} />
            </>
          </Dragger>
        </Form.Item>

        <div className='btn-block'>
          <Button text={'Cancel'} btnType={'text'} />
          <Button text={'Save'} btnType={'primary'} htmlType={'submit'} />
        </div>
      </Form>
    </>
  )
}
export default UploadReceipt
