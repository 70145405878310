import { FeatureCollection, Point } from 'geojson'
import { FC } from 'react'
import { Layer, Source } from 'react-map-gl'

type Props = {
  locationPoint: FeatureCollection<Point>
}
const MarkerCircle:FC<Props> = ({locationPoint}) => {
  return (
    <Source
      key={'1'}
      id={'project'}
      type={'geojson'}
      data={locationPoint}
    >
      <Layer
        id={'project'}
        type={'circle'}
        paint={{
          'circle-radius': 10,
          'circle-color': 'orange',
          'circle-stroke-color': '#f76714',
          'circle-stroke-width': 3,
          'circle-opacity': 0.3,
        }}
      />
    </Source>
  )
}

export default MarkerCircle
