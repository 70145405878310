import { DateFormat } from '@/helpers/globalVariables'
import { Checkbox, Col, DatePicker, DatePickerProps, Form, Input, RadioChangeEvent, Row, Select, TimePicker } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { RangePickerProps } from 'antd/es/date-picker'
import { NamePath } from 'antd/lib/form/interface'
import moment from 'moment'
import React, { BaseSyntheticEvent, FC, ReactNode } from 'react'

import { SvgIcon } from '../icon'
import NotFoundContent from '../not-found-content/NotFoundContent'
import './FormItemDivider.less'
import { Rule } from 'antd/lib/form'

export type SelectOptionsType = {
  label: string | null | undefined | ReactNode
  value: string | null | undefined
  key?: string
}
export type FormElementsType = 'time-picker'
  | 'input'
  | 'select'
  | 'multi-select'
  | 'multi-tags'
  | 'textarea'
  | 'date-picker'
  | 'date-time-range-picker'
  | 'password'
  | 'input-email'
  | 'custom-multi-select'
  | 'multi-select-search'
| 'checkbox-group'
const { RangePicker } = DatePicker
type Props = {
  defaultValue?: string | null | undefined
  defaultSelectValues?: string[] | null | undefined
  handleChange?: (value: string | string[] | CheckboxValueType[] | RadioChangeEvent) => void
  handleRadioChange?: (e: RadioChangeEvent) => void
  handleSelectChange?: ((value: string, option: SelectOptionsType | SelectOptionsType[]) => void) | undefined
  handleMultiChange?: ((value: string[], option: SelectOptionsType | SelectOptionsType[]) => void) | undefined
  label?: string
  name: NamePath
  onRangeTimeChange?: (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => void
  onRangeTimeOk?: (value: DatePickerProps['value'] | RangePickerProps['value']) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  rules?: Rule[]
  selectOptions?: SelectOptionsType[] | undefined
  type:FormElementsType
  onInput?: (e: BaseSyntheticEvent) => void
  onOpenChange?: () => void
  disabledDate?: DatePickerProps['disabledDate']
  isRemoveItemAllowed?: boolean
  isDisabled?: boolean
  loading?: boolean
  notFoundText?: string
  onDateChange?: (value: moment.Moment | null) => void
  currentDateToDisabled?: moment.Moment | null
  helpText?: string
  handleSearch?: (searchText: string) => Promise<void>
  rows?: number
  onChange?: (e: BaseSyntheticEvent) => void
}

const icons = {
  close: <SvgIcon type={'close-notification'} />,
  search: <SvgIcon type={'magnifying-glass'} />,
}
export const theme = {
  variables: {
    unit: '14px',
    padding: '0.5em',
    borderRadius: '0',
    border: '1px solid',
    boxShadow: 'unset',
  },
  // icons: icons,
}
const FormItem: FC<Props> = ({
                               handleMultiChange,
                               required = true,
                               readOnly = false,
                               selectOptions,
                               name,
                               label,
                               type,
                               placeholder,
                               defaultValue,
                               defaultSelectValues,
                               rules,
                               onRangeTimeChange,
                               onRangeTimeOk,
                               onInput,
                               onOpenChange,
                               isRemoveItemAllowed = false,
                               isDisabled = false,
                               notFoundText,
                               handleSelectChange,
                               onDateChange,
                               currentDateToDisabled,
                               helpText,
                               handleSearch,
  rows = 3,
  loading
                             }) => {

  const disabledDates: DatePickerProps['disabledDate'] = (current) => {
    if (currentDateToDisabled) {
      return current < currentDateToDisabled.endOf('day')
    } else {
      return current && current < moment().endOf('day').subtract(1, 'day')
    }
  }

  return (
    <>
      {type === 'input' && (
        <Form.Item label={label} name={name} rules={rules ? rules : [{ required, message: 'This field is required' }]}>
          <Input placeholder={placeholder} readOnly={readOnly} disabled={isDisabled} onInput={onInput} />
        </Form.Item>
      )}
      {type === 'input-email' && (
        <Form.Item
          label={label}
          name={name}
          required={required}
          rules={[
            { required, message: 'This field is required' },
            { type: 'email', message: 'Enter a correct email' },
          ]}
        >
          <Input placeholder={placeholder} readOnly={readOnly} />
        </Form.Item>
      )}
      {type === 'password' && (
        <Form.Item label={label} name={name} rules={rules}>
          <Input.Password placeholder={placeholder} readOnly={readOnly} onInput={onInput} disabled={isDisabled} />
        </Form.Item>
      )}
      {type === 'textarea' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <Input.TextArea
            readOnly={readOnly}
            placeholder={placeholder}
            rows={rows}
            autoSize={true}
            disabled={isDisabled}
          />
        </Form.Item>
      )}
      {type === 'select' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <Select
            placeholder={placeholder}
            options={selectOptions}
            suffixIcon={<SvgIcon type={'down'} />}
            aria-readonly={readOnly}
            disabled={readOnly || isDisabled}
            defaultValue={defaultValue ? defaultValue : null}
            notFoundContent={<NotFoundContent text={notFoundText ? notFoundText : ''} />}
            onChange={handleSelectChange}
            loading={loading}
          />
        </Form.Item>
      )}

      {type === 'multi-select-search' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText}>
          <Select
            className={'street-multiselect'}
            mode={"multiple"}
            placeholder={placeholder}
            notFoundContent={null}
            onSearch={handleSearch}
            options={selectOptions}
            removeIcon={<SvgIcon type={'close-notification'} height={12} />}
          />
        </Form.Item>
      )}
      {type === 'multi-select' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText}>
          <Select
            mode={'multiple'}
            placeholder={placeholder}
            suffixIcon={<SvgIcon type={'download'} />}
            aria-readonly={readOnly}
            disabled={readOnly}
            options={selectOptions}
            onChange={handleMultiChange}
            allowClear={true}
            defaultValue={defaultSelectValues}
            notFoundContent={<NotFoundContent />}
            removeIcon={<SvgIcon type={'close-notification'} height={12} />}
          />
        </Form.Item>
      )}
      {type === 'multi-tags' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText}>
          <Select
            mode={'tags'}
            placeholder={placeholder}
            suffixIcon={<SvgIcon type={'download'} />}
            aria-readonly={readOnly}
            disabled={readOnly}
            options={selectOptions}
            onChange={handleMultiChange}
            allowClear={false}
            defaultValue={defaultSelectValues}
            notFoundContent={<NotFoundContent text={'Enter New Name'} />}
            removeIcon={<SvgIcon type={'close-notification'} height={12} />}
          />
        </Form.Item>
      )}
      {type === 'date-picker' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <DatePicker
            suffixIcon={<SvgIcon type={'calendar'} />}
            placeholder={placeholder ? placeholder : 'Select Date'}
            placement={'bottomRight'}
            showToday={false}
            popupClassName={'picker-custom'}
            disabled={readOnly}
            onOpenChange={onOpenChange}
            disabledDate={disabledDates}
            format={DateFormat}
            onChange={onDateChange}
          />
        </Form.Item>
      )}
      {type === 'time-picker' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <TimePicker
            placeholder={placeholder ? placeholder : 'Select Time'}
            placement={'bottomRight'}
            // popupClassName={'picker-custom'}
            disabled={readOnly}
            onOpenChange={onOpenChange}
            disabledDate={disabledDates}
            format={'HH:mm'}
            onChange={onDateChange}
          />
        </Form.Item>
      )}
      {type === 'date-time-range-picker' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format='DD MMM YYYY HH:mm'
            onChange={onRangeTimeChange ? onRangeTimeChange : () => null}
            onOk={onRangeTimeOk ? onRangeTimeOk : () => null}
            disabled={readOnly}
            popupClassName={'picker-custom'}
            placement={'bottomRight'}
            placeholder={['Select start date', 'Select end date']}
            suffixIcon={<SvgIcon type={'calendar'} />}
          />
        </Form.Item>
      )}
      {type === 'checkbox-group' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <Checkbox.Group style={{ width: '100%' }} onChange={()=>''}>
            <Row>
              {selectOptions?.map(it => (
                <Col span={6} key={it?.value}>
                  <Checkbox value={it?.value}>{it?.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      )}
    </>
  )
}

export default FormItem
