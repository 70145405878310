import { FC } from "react"
import "./EscapeButton.less"

import { useNavigate } from "react-router"

import { SvgIcon } from "../icon"

type Props = {
  escLink?: string
  onClick?: () => void
}
const EscapeButton: FC<Props> = ({ escLink, onClick }) => {
  const navigate = useNavigate()
  const onEscape = () => {
    onClick ? onClick() : null
    if (escLink) {
      navigate(escLink)
    }
    if (!escLink && !onClick) {
      navigate(-1)
    }
  }
  return (
    <div onClick={onEscape} className={'cross-wrapper'}>
      <SvgIcon type={"cross"} className={"cross-icon"} />
      <SvgIcon type={"esc"} />
    </div>
  )
}

export default EscapeButton
