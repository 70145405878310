import { MutableRefObject } from 'react'
import { MapRef } from 'react-map-gl'
import { mapImageGeneratorFromBase64 } from '@/components/google-map/helpers/mapImageGeneratorFromBase64'
import { sleep } from '@/helpers/sleeper'

export const getMapSnapshot = async (mapRef: MutableRefObject<MapRef | undefined>): Promise<(UploadFileInput & { id: string })[] | undefined> => {
  let mapImage
  const map = mapRef?.current?.getMap()
  /* trigger render */
  map?.setBearing(map?.getBearing())
  map?.once('render', async function(e) {
    const base64string = e.target.getCanvas().toDataURL()
    mapImage = await mapImageGeneratorFromBase64(base64string)
  })
  await sleep(1000)
  return mapImage
}

export const getMapSnapshotBase64string = async (mapRef: MutableRefObject<MapRef | undefined>): Promise<string | undefined> => {
  let mapImage
  const map = mapRef?.current?.getMap()
  /* trigger render */
  map?.setBearing(map?.getBearing())
  map?.once('render', async function(e) {
    mapImage = e.target.getCanvas().toDataURL()
  })
  await sleep(1000)
  return mapImage
}