import { dataUrlToFile } from '@/utilities/urlToFile'
import { getAuthToken } from '@/helpers/auth'

interface MapImageGenerator {
  (base64Data: string): Promise<UploadFileFiltersInput[]>;
}

export const mapImageGeneratorFromBase64: MapImageGenerator = async (base64Data) => {
  const token = getAuthToken()

  const file = await dataUrlToFile(base64Data, 'map-image')
  const form = new FormData()

  form.append('files', file, 'map-screenshot.png')
  const responseUpload = await fetch(
    `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
    {
      headers: { Authorization: `Bearer ${token}` },
      method: 'post',
      body: form,
    },
  )
  return await responseUpload.json()
}