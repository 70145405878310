import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItem, { SelectOptionsType } from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import MarkerCircle from '@/components/google-map/marker-circle/MarkerCircle'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import useSaveAsDraft from '@/components/modal/modal-content/hooks/useSaveKickoffInvite'
import MultiSelectUserItem from '@/components/multi-select-user-item/MultiSelectUserItem'
import { useApplicationsQuery, useCompaniesQuery, useInviteRequiredDocTypesQuery, useKickoffInviteQuery, useSendInviteMutation, useUserQuery } from '@/graphql'
import { Col, Form, Popconfirm, Row, Space, Spin } from 'antd'
import { Feature, FeatureCollection, Point } from 'geojson'
import moment from 'moment'
import { FC, Ref, useEffect, useRef, useState } from 'react'
import { LngLatLike, MapRef, Marker, MarkerDragEvent } from 'react-map-gl'
import { useSearchParams } from 'react-router-dom'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import markerIcon from '../../google-map/images/marker-06.png'


const { Title, Text } = Typography
const CreateKickoffInvite: FC = () => {
  const { role , user} = useAuth()
  const mapRef = useRef<MapRef>()
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const { setIsModalOpen, currentId } = useGeneralContext()
  const [sendInvite, {loading: sendingInvite}] = useSendInviteMutation()

  const uuid = searchParams.get('id') as string

  const [location, setLocation] = useState<Point | null>(null)
  const [selectedEmails, setSelectedEmails] = useState<string[]>([])

  const { data, loading: applicationLoading } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: uuid } } },
  })
  const { data: currentKickoffData, loading: currentKickoffLoading } = useKickoffInviteQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })

  const currentKickoff = currentKickoffData?.kickoffInvite?.data as KickoffInviteEntity
  const itemId = currentKickoff?.id

  const companiesEmails = currentKickoff?.attributes?.participants?.map(it => it?.email).toString()
  const appData = data?.applications?.data?.[0] as ApplicationEntity

  const approvalEnvelopCompanyIds = appData?.attributes?.approvalEnvelop?.map(it => it?.companyId)
  const { data: companiesData } = useCompaniesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {
        id: { in: approvalEnvelopCompanyIds },
      },
    },
  })
  const { data: appOwnerData, loading: appOwnerLoading } = useUserQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      id: appData?.attributes?.owner?.data?.id || '',
    },
  })
  const appOwner = appOwnerData?.usersPermissionsUser?.data as UsersPermissionsUserEntity

  const currentApplicationCompanies = companiesData?.companies?.data

  const applicationCenterFeature = appData?.attributes?.projectCenter as Feature
  const applicationCenter = currentKickoff ? currentKickoff?.attributes?.location as Point : applicationCenterFeature?.geometry as Point

  const { data: docTypesData, loading: docTypesLoading } = useInviteRequiredDocTypesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const docTypes = docTypesData?.inviteRequiredDocumentTypes?.data
  const documentsOptions: SelectOptionsType[] = docTypes?.map(it => ({
    label: it?.attributes?.name || '',
    value: it?.id || '',
  })) || []

  const participantsOptions: SelectOptionsType[] = appData?.attributes?.approvalEnvelop?.filter(it => it?.isSiteVisitRequired).map(it => ({
    label: it?.companyName || '',
    value: it?.companyId || '',
  })) || []

  const clientParticipants = [
    {
      label: (
        <MultiSelectUserItem
          name={appOwner?.attributes?.userProfile?.data?.attributes?.fullName + ' (client)'}
          email={appOwner?.attributes?.userProfile?.data?.attributes?.email}
        />
      ),
      value: appOwner?.id,
    },
  ]

  useEffect(() => {
    if (role === 'agent') {
      setSelectedEmails([appOwner?.attributes?.email || ''])
    }
  }, [appOwner?.attributes?.email, role])

  useEffect(() => {
    if (!applicationLoading && applicationCenter && !currentKickoffLoading && !currentKickoff) {
      setLocation(applicationCenter)
    }
    if (!currentKickoffLoading && currentKickoff) {
      setLocation(currentKickoff?.attributes?.location as Point)
    }
  }, [applicationCenter, applicationLoading, currentKickoff, currentKickoffLoading])

  const { onSaveAsDraft, loading } = useSaveAsDraft({
    mapRef,
    appData,
    location,
  })

  const onMarkerDrag = (e: MarkerDragEvent) => {
    setLocation({
      type: 'Point',
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    })
  }

  const onLoad = () => {

    mapRef?.current?.setCenter(applicationCenter?.coordinates as LngLatLike)
    mapRef?.current?.easeTo({ zoom: 14 })

    // if(mapRef?.current) {
    //   new mapboxgl.Marker()
    //     .setLngLat([location?.coordinates?.[0] || 0, location?.coordinates?.[1] || 0])
    //     .addTo(mapRef?.current)
    // }
    mapRef?.current?.loadImage(markerIcon, (error, image)=> {
      if (error) throw error;
      if (!mapRef?.current?.hasImage('custom-marker-icon') && image) {
        mapRef?.current?.addImage('custom-marker-icon', image);
      }
    })
  }
  const onSaveAndSubmit = async () => {
    const values = await form.validateFields()
    const resultId = await onSaveAsDraft(values, itemId, appOwner, 'sent')
    if (resultId) {
      await sendInvite({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: { inviteId: resultId, organizationId: user?.organization?.data?.id || '' },
        },
        onCompleted: () => {
          BlackNotification('Invite sent successfully')
          setIsModalOpen(false)
        },
        onError: (error) => {
          BlackNotification(error.message)
        },
      })
    }
  }

  const handleSaveAsDraft = async (values: KickoffInviteInput) => {
    await onSaveAsDraft(values, itemId, appOwner, 'draft')
    setIsModalOpen(false)
  }


  const onParticipantsChange = (idArray: string[]) => {
    console.log(idArray)
    console.log(currentKickoff)
    if (!currentKickoff) {

      if (role === 'client') {
        const selectedCompaniesEmails = currentApplicationCompanies?.filter(it => it?.id && idArray?.includes(it?.id))?.map(it => it?.attributes?.companyEmail)
        setSelectedEmails(selectedCompaniesEmails as string[])
      }
      if (role === 'agent') {
        setSelectedEmails([appOwner?.attributes?.email || ''])
      }
    }

  }
  let initialValues = {}
  if (role === 'agent' && !currentKickoff) {
    initialValues = {
      participants: [appOwner?.id || ''],
    }
  }
  if (currentKickoff) {
    initialValues = {
      date: moment(currentKickoff?.attributes?.date, 'YYYY-MM-DD'),
      time: moment(currentKickoff?.attributes?.time, 'HH:mm SSS'),
      description: currentKickoff?.attributes?.description,
      participants: currentKickoff?.attributes?.participants?.map(it => it?.userId),
      inviteRequiredDocumentTypes: currentKickoff?.attributes?.inviteRequiredDocumentTypes?.data?.map(it => it?.id),
    }
  }
  const locationPoint = {
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'geometry': location,
      },
    ],
  } as FeatureCollection<Point>
  return applicationLoading || currentKickoffLoading || docTypesLoading || appOwnerLoading ? (
    <Spin />
  ) : (
    <>
      <Title level={3}>{currentKickoff ? 'Edit Kickoff invite' : 'Create Kickoff invite'}</Title>
      <FormItemDivider />
      <Space direction={'vertical'} style={{ width: '100%', paddingTop: 8 }} size={'middle'}>
        <Text size={'md'}>{`${currentKickoff ? 'Edit' : 'Create'} you kickoff invite and send it to participants`}</Text>
        <Row>
          <Col span={24}>
            <MapBoxMap
              mapRef={mapRef as Ref<MapRef>}
              isDrawTools={false}
              isGeometryVisibilityToggle={false}
              mapContainerHeight={300}
              onLoad={onLoad}
            >
              {location && (
                <Marker
                  key={'center'}
                  longitude={location?.coordinates?.[0]}
                  latitude={location?.coordinates?.[1]}
                  draggable={true}
                  onDrag={onMarkerDrag}
                />
              )}
              <MarkerCircle locationPoint={locationPoint}/>

              {/*  <Marker*/}
              {/*    key={'center'}*/}
              {/*    longitude={location?.coordinates?.[0]}*/}
              {/*    latitude={location?.coordinates?.[1]}*/}
              {/*    draggable={true}*/}
              {/*    onDragEnd={onMarkerDrag}*/}
              {/*  >*/}
              {/*    <Button*/}
              {/*      btnType={'icon'}*/}
              {/*      icon={<SvgIcon type={'map-pin-drop'} />}*/}
              {/*      style={{ backgroundColor: 'transparent' }}*/}
              {/*    />*/}
              {/*  </Marker>*/}
              {/*  */}

              {/*<Source*/}
              {/*  id={'project-symbol'}*/}
              {/*  type={'geojson'}*/}
              {/*  data={locationFeatureCollectionPoint}*/}
              {/*>*/}
              {/*  <Layer*/}
              {/*    id={'project-symbol-layer'}*/}
              {/*    type={'symbol'}*/}
              {/*    layout={{*/}
              {/*      'icon-image': 'marker-icon', // Use a Mapbox-provided icon or your custom icon*/}
              {/*      'icon-size': 1.5, // Adjust size as needed*/}
              {/*      'text-field': ['get', 'title'], // Use property from GeoJSON data*/}
              {/*      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],*/}
              {/*      'text-offset': [0, 1.25],*/}
              {/*      'text-anchor': 'top',*/}
              {/*    }}*/}
              {/*    paint={{*/}
              {/*      'text-color': '#f76714',*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Source>*/}

              {/*<Source*/}
              {/*  id={'project-symbol'}*/}
              {/*  type={'geojson'}*/}
              {/*  data={locationFeatureCollectionPoint}*/}
              {/*>*/}
              {/*  <Layer*/}
              {/*    id={'project-symbol-layer'}*/}
              {/*    type={'symbol'}*/}
              {/*    layout={{*/}
              {/*      'icon-image': 'custom-marker-icon', // Use the reference name of your icon*/}
              {/*      'icon-size': 0.05, // Adjust the size as needed*/}
              {/*      'icon-anchor': 'bottom', // Anchor the icon appropriately*/}
              {/*      'icon-rotate': 180,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Source>*/}
            </MapBoxMap>
            <Text>{'Drag marker if needed to specify the exact place of meeting.'}</Text>
          </Col>
        </Row>
        <Form
          layout={'vertical'}
          size={'middle'}
          onFinish={handleSaveAsDraft}
          form={form}
          style={{ padding: '30px 0' }}
          initialValues={initialValues}
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem name={'date'} type={'date-picker'} label={'Date of meeting'} />
            </Col>
            <Col span={12}>
              <FormItem name={'time'} type={'time-picker'} label={'Time of meeting'} />
            </Col>
          </Row>
          <FormItem name={'description'} type={'textarea'} placeholder={'Enter description'} rows={5} label={'Description'} />
          <FormItem
            name={'participants'}
            type={'multi-select'}
            placeholder={'Select participants'}
            label={'Participants'}
            selectOptions={role === 'client' ? participantsOptions : clientParticipants}
            handleMultiChange={onParticipantsChange}
            loading={appOwnerLoading}
          />
          <FormItem
            name={'inviteRequiredDocumentTypes'}
            type={'checkbox-group'}
            label={'Required Documents'}
            selectOptions={documentsOptions}
          />
          <Row justify={'end'} style={{ paddingTop: 24 }}>
            <Button text={itemId ? 'Update draft invite' : 'Save as draft'} btnType={'text'} htmlType={'submit'} loading={loading} />
            <Popconfirm
              placement={'topRight'}
              title={<Col style={{ maxWidth: '300px' }}>
                <Text>{`Invitation will be sent to the following email addresses: `}</Text>
                <Text weight={'w600'}>{currentKickoff ? companiesEmails : selectedEmails}</Text>
              </Col>}
              onConfirm={onSaveAndSubmit}
              okText={'Send'}
              cancelText={'Cancel'}
              cancelButtonProps={{loading: sendingInvite}}
              okButtonProps={{loading: sendingInvite}}
            >
              <Button text={'Save and Send invite'} btnType={'primary'} loading={loading} />
            </Popconfirm>
          </Row>
        </Form>
      </Space>
    </>
  )
}
export default CreateKickoffInvite
