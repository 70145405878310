import Button from '@/components/button/Button'
import useOrganizationData from '@/hooks/useOrganizationData'
import { Tooltip } from 'antd'
import classnames from 'classnames'
import { FC } from 'react'
import './Logo.less'

interface LogoProps {
  minified?: boolean;
  onClick: () => void;
  collapsed: boolean
}

const Logo: FC<LogoProps> = ({ onClick, collapsed }) => {
  const { organizationData, loading } = useOrganizationData()

  const classes = classnames({
    'sider-logo-wrapper': true,
    'collapsed': collapsed,
  })
  return (
    <Tooltip title={`${collapsed ? 'Expand' : 'Minimize'} sidebar`} placement={'right'}>
      <div className={classes}>
        <Button type={'link'} className={'sider-logo-button'} onClick={onClick} loading={loading}>
          {collapsed ? (
            <img src={organizationData?.attributes?.singleLogo?.data?.attributes?.url} alt={'logo'} className={'sider-logo'} />
          ) : (
            <img src={organizationData?.attributes?.logoWithCompanyName?.data?.attributes?.url} alt={'logo'} className={'sider-full-company-logo'} />
          )}
        </Button>
      </div>
    </Tooltip>
  )
}

export default Logo
