import DocCard from "../../doc-card/DocCard"

const ProvidersAttachment = () => {
  return (
    <>
      {/*<DocCard title={'Attached doc-10'} size={'12.0'} />*/}
      {/*<DocCard title={'Attached doc-11'} size={'14.0'} />*/}
    </>
  )
}
export default ProvidersAttachment
