import React, { FC } from "react"
import Typography from "../typography/Typography"
import welcomeImage from "./images/welcome.png"
import "./TablePlaceholder.less"

const { Text } = Typography
type Props = {
  title?: string
  text?: string
}
const TablePlaceholder: FC<Props> = ({ title, text }) => {
  return (
    <div className='welcome-picture-container'>
      <div className='welcome-picture-wrapper'>
        <div className='img-wrapper'>
          <img src={welcomeImage} alt='' />
        </div>
        <Text size={"md"} weight={"w600"} style={{ display: "block", marginBottom: 8 }} centered>
          {title ? title : "Welcome to Ground Work"}
        </Text>
        <Text color={"gray"} centered>
          {text ? text : "Start by create application or project!"}
        </Text>
      </div>
    </div>
  )
}

export default TablePlaceholder
