export const checkApplicationDocuments = (applicationData: Maybe<Application>) => {
  const checkArray: boolean[] = []
  applicationData?.requiredDocuments?.forEach(it => {
    if (it?.documents?.data?.length) {
      checkArray.push(true)
    } else {
      checkArray.push(false)
    }
  })
  return !checkArray.includes(false)
}
export const checkApplicationPayments = (applicationsData: Application) => {
  const appInvoice = applicationsData?.invoice?.data as InvoiceEntity
  return appInvoice && appInvoice?.attributes?.status === 'paid'
}
