import CreateKickoffInvite from '@/components/modal/modal-content/CreateKickoffInvite'
import CreateKickoffRecord from '@/components/modal/modal-content/CreateKickoffRecord'
import DeleteApplication from '@/components/modal/modal-content/DeleteApplication'
import InvoiceApprove from '@/components/modal/modal-content/InvoiceApprove'
import UserProfileReview from '@/components/modal/modal-content/UserProfileReview'
import { FC } from 'react'
import ApprovalDetails from '../../pages/application/content/approval-details/ApprovalDetails'
import { useGeneralContext } from '../context/GeneralContext'
import ApplicationAssignConfirmation from './modal-content/ApplicationAssignConfirmation'
import ApplicationAssignStep01 from './modal-content/ApplicationAssignStep01'
import ApplicationAssignStep02 from './modal-content/ApplicationAssignStep02'
import CreateApplicationOrganizationInfo from './modal-content/CreateApplicationOrganizationInfo'
import CreatedProjectInfo from './modal-content/CreatedProjectInfo'
import ApplicationReport from './modal-content/ApplicationReport'
import AssignProject from './modal-content/AssignProject'
import AssignUser from './modal-content/AssignUser'
import ConfirmQuotation from './modal-content/ConfirmQuotation'
import CreateApplication from './modal-content/CreateApplication'
import CreateApplicationAdditionalInfo from './modal-content/CreateApplicationAdditionalInfo'
import CreateApplicationSuccess from './modal-content/CreateApplicationSuccess'
import CreateCompany from './modal-content/CreateCompany'
import CreatePaymentContent from './modal-content/CreatePaymentContent'
import CreateProjectSuccess from './modal-content/CreateProjectSuccess'
import CreateQuotationItem from './modal-content/CreateQuotationItem'
import DeleteProject from './modal-content/DeleteProject'
import GeneratedApplications from './modal-content/GeneratedApplications'
import MapSavingInfo from './modal-content/MapSavingInfo'
import PaymentApprove from './modal-content/PaymentApprove'
import PaymentOptions from './modal-content/PaymentOptions'
import PaymentSuccess from './modal-content/PaymentSuccess'
import ProvidersAttachment from './modal-content/ProvidersAttachment'
import RegisterSuccess from './modal-content/RegisterSuccess'
import RequestInfo from './modal-content/RequestInfo'
import SendQuotation from './modal-content/SendQuotation'
import SimpleModal from './modal-content/SimpleModal'
import UploadReceipt from './modal-content/UploadReceipt'
import SelectedProjectInfo from './modal-content/SelectedProjectInfo'
import DeleteKickoffRecord from './modal-content/DeleteKickoffRecord'
import DeleteKickoffInvite from './modal-content/DeleteKickoffInvite'

const ModalContent:FC = () => {
  const { modalType } = useGeneralContext()

  const modalContentGenerator = () => {
    switch (modalType) {
      case 'simple':
        return <SimpleModal />

      case 'delete-kickoff-invite':
        return <DeleteKickoffInvite />

      case 'delete-kickoff-record':
        return <DeleteKickoffRecord />

      case 'register-success':
        return <RegisterSuccess />

      case 'assign-project':
        return <AssignProject />

      case 'approve-application':
        return <ApprovalDetails mode={'approval'} />

      case 'application-assign-confirmation':
        return <ApplicationAssignConfirmation />

      case 'providers-attach':
        return <ProvidersAttachment />

      case 'application-assign-step-01':
        return <ApplicationAssignStep01 />

      case 'application-assign-step-02':
        return <ApplicationAssignStep02 />

      case 'map-saving-info':
        return <MapSavingInfo />

      case 'application-report':
        return <ApplicationReport />

      case 'create-application':
        return <CreateApplication />

      case 'create-application-organization':
        return <CreateApplicationOrganizationInfo />

      case 'confirm-quotation':
        return <ConfirmQuotation />

      case 'create-payment':
        return <CreatePaymentContent />

      case 'create-application-success':
        return <CreateApplicationSuccess />

      case 'create-application-info':
        return <CreateApplicationAdditionalInfo />

      case 'application-info':
        return <CreatedProjectInfo />

      case 'selected-project-info':
        return <SelectedProjectInfo />

      case 'project-created-success':
        return <CreateProjectSuccess />

      case 'payment-options':
        return <PaymentOptions />

      case 'payment-success':
        return <PaymentSuccess />

      case 'request-info':
        return <RequestInfo />

      case 'upload-receipt':
        return <UploadReceipt />

      case 'generated-applications':
        return <GeneratedApplications />

      case 'payment-approve':
        return <PaymentApprove />

      case 'invoice-approve':
        return <InvoiceApprove />

      case 'send-quotation':
        return <SendQuotation />

      case 'assign-user':
        return <AssignUser />

      case 'create-company':
        return <CreateCompany />

      case 'user-profile-review':
        return <UserProfileReview />

      case 'create-quotation-item':
        return <CreateQuotationItem />

      case 'delete-project-confirmation':
        return <DeleteProject />
      
      case 'delete-application-confirmation':
        return <DeleteApplication />

      case 'create-kickoff-record':
        return <CreateKickoffRecord />

      case 'create-kickoff-invite':
        return <CreateKickoffInvite />

      default:
        return <ApplicationAssignConfirmation />
    }
  }
  return <div className={'modal-content'}>{modalContentGenerator()}</div>
}

export default ModalContent
