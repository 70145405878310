import Button from '@/components/button/Button'
import { SvgIcon } from '@/components/icon'
import { downloadFile, getUrlExtension } from '@/utilities/utilities'
import { Col, Modal, Row, UploadFile } from 'antd'
import React, { FC, useState } from 'react'
import './UploadFileItem.less'
import { Document, Page, pdfjs } from 'react-pdf'
import Typography from '../typography/Typography'


const { Text } = Typography
type Props = {
  onPreview: () => void
  file: UploadFile
  onRemove: () => void
  onDownload: () => void
  isReadOnly: boolean
}
const UploadFileItem: FC<Props> = ({ onRemove, file, isReadOnly }) => {
  console.log(file)
  const [isPreviewOpened, setIsPreviewOpened] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const extension = getUrlExtension(file?.url || file?.response?.[0]?.url)
  const fileUrl = file?.url || file?.response?.[0]?.url || ''

  function onDocumentLoadSuccess(props: { numPages: number }) {
    const numPages = props.numPages
    setPageNumber(numPages)
  }
const onPreview = () => {
  setIsPreviewOpened(true)
}
  const handleDownload = (e: React.SyntheticEvent) => {
    console.log('download', file)
    e.stopPropagation()
    if (file.url) {
      downloadFile(file.url)
    }
    if (file?.response?.[0]?.url) {
      downloadFile(file?.response?.[0]?.url)
    }
  }

  return (
    <>

      <Row className={'upload-file-item'} justify={'space-between'} align={'middle'}>
        <Col flex={'auto'}>
          <Row align={'middle'}>

            <Col className={'upload-file-image-preview'} onClick={onPreview}>
              {extension === 'pdf' ?
                <SvgIcon type={'doc'} /> :
                <img src={file.url ? file.url : file?.thumbUrl} alt={''} />
              }
            </Col>
            <Text size={'sm'}>{file?.fileName ? file.fileName : file?.name}</Text>
          </Row>
        </Col>
        <Col>
          <Row>
            {/*<Space direction={'horizontal'}>*/}
            <Button btnType={'icon'} icon={<SvgIcon type={'download'} />} onClick={handleDownload} />
            {!isReadOnly && (

              <Button btnType={'icon'} icon={<SvgIcon type={'bin'} />} onClick={onRemove} />
            )}
            {/*</Space>*/}
          </Row>
        </Col>

      </Row>
      <Modal
        closeIcon={<SvgIcon type={'close-modal'} />}
        open={isPreviewOpened}
        onCancel={() => setIsPreviewOpened(false)}
        className={'preview-image-modal'}
        footer={null}
        width={600}
      >
        {(extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'webp') && (
          <div>
            <img src={fileUrl} alt={'image'} style={{ width: '100%' }} />
          </div>
        )}
        {extension === 'pdf' && (
          <div>
            <Document
              file={{ url: fileUrl }}
              options={{ enableXfa: false, cMapPacked: false, disableStream: true, isEvalSupported: false }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} width={550} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
          </div>
        )}
      </Modal>
    </>
  )
}

export default UploadFileItem
