import { useAuth } from '@/components/context/AuthProvider'
import { message } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useApplicationsQuery,
  useCreateNotificationMessageMutation,
  useCreatePaymentMutation,
  useUpdateApplicationMutation,
} from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const { Text, Paragraph } = Typography
const CreatePaymentContent = () => {
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id')
  const { setIsModalOpen, setIsPaymentCreated, setTriggerRefetch } = useGeneralContext()
  const [createPayment] = useCreatePaymentMutation()
  const [updateApplication] = useUpdateApplicationMutation()
  const [createMessage] = useCreateNotificationMessageMutation()

  const [isDisabled, setIsDisabled] = useState(false)
  const { data } = useApplicationsQuery({ variables: { filter: { uuid: { eq: uuid } } } })
  const appData = data?.applications?.data?.[0]
  const date = moment().format('YYYY-MM-DD')
  const currentApplicationId = appData?.id as string

  const onRequestQuotation = async () => {
    setIsDisabled(true)
    try {
      const payment = await createPayment({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            title: appData?.attributes?.title + '-' + date,
            application: currentApplicationId,
            status: 'requested',
            type: 'quotation',
          },
        },
      })
      if (!payment?.data?.createPayment?.data) {
        message.error('Error quotation request').then()
      }
      const updatedApplication = await updateApplication({
        fetchPolicy: globalFetchPolicy,
        variables: {
          id: currentApplicationId,
          data: {
            status: 'payment_requested',
          },
        },
      })

      if (!updatedApplication?.data?.updateApplication?.data) {
        message.error('Error updating application').then()
      }

      await createMessage({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            title: 'Application status',
            message: `Quotation for application No${appData?.attributes?.title} has been requested`,
            application: appData?.id,
            from: user?.id,
            to: appData?.attributes?.agent?.data?.id,
          },
        },
        onError: (error) => {
          message.error(error.message)
        },
      })

      setIsModalOpen(false)
      setIsPaymentCreated(true)
      setTriggerRefetch(true)
      BlackNotification('Request for quotation sent successfully')
    } catch (e) {
      console.log(e)
    }
  }
  const onRequestInvoice = async () => {
    setIsDisabled(true)

    try {
      const createdPayment = await createPayment({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            title: appData?.attributes?.title + '-' + date,
            application: currentApplicationId,
            status: 'requested',
            type: 'invoice',
          },
        },
      })
      if (!createdPayment?.data?.createPayment?.data) {
        message.error('Error quotation request').then()
      }
      const updatedApplication = await updateApplication({
        fetchPolicy: globalFetchPolicy,
        variables: {
          id: currentApplicationId,
          data: {
            status: 'payment_requested',
          },
        },
      })

      await createMessage({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            title: 'Application status',
            message: `Invoice for application No${appData?.attributes?.title} is requested`,
            application: appData?.id,
            from: user?.id,
            to: appData?.attributes?.agent?.data?.id,
          },
        },
      })
        .then()
        .catch(e => console.error(e))

      if (!updatedApplication?.data?.updateApplication?.data) {
        message.error('Error updating application').then()
      }
      setIsModalOpen(false)
      setIsPaymentCreated(true)
      BlackNotification('Request for invoice sent successfully')
      setTriggerRefetch(true)
    } catch (error) {
      message.error('Error invoice request').then()
    }
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Payment request'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'}>
        {'You can request a quotation or invoice'}
      </Paragraph>
      <div className='btn-block'>
        <Button text={'Request Quotation'} btnType={'text'} onClick={onRequestQuotation} loading={isDisabled} />
        <Button text={'Request Invoice'} btnType={'primary'} onClick={onRequestInvoice} loading={isDisabled} />
      </div>
    </>
  )
}
export default CreatePaymentContent
